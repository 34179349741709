var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NavigationBar',{attrs:{"titel":"Fristenüberwachung","isPreview":""}}),_c('TippCard',{attrs:{"hintID":"Mitgliederverwaltung_Fristenüberwachung_1_V1","text":"Text"}}),_c('section',[[_c('v-container',[_c('v-row',{attrs:{"justify":"center","align":"start"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"7"}},[_c('Subheader',{attrs:{"icon":"mdi-binoculars","title":"Überwachte Fristen","actions":_vm.actions}}),_c('div',{staticClass:"mt-7"},[_c('v-data-iterator',{attrs:{"items":_vm.fristen,"item-key":"meta.id","sort-desc":false,"no-data-text":"Es werden keine Fristen überwacht"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',_vm._l((props.items),function(item){return _c('v-col',{key:item.meta.id,attrs:{"cols":"12"}},[_c('v-card',{attrs:{"link":""},on:{"click":function($event){return _vm.routeDetails(item.meta.id)}}},[_c('v-card-title',{staticClass:"subheading"},[_vm._v(" "+_vm._s(item.titel)+" "),_c('v-spacer'),_c('v-icon',[_vm._v("mdi-chevron-right")])],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},_vm._l((item.watchers),function(watcher){return _c('v-col',{key:watcher.color,attrs:{"cols":"sm"}},[_c('v-card',{staticClass:"pa-2",attrs:{"color":`${watcher.color} lighten-4`,"dark":"","disabled":watcher.value == 0,"outlined":""}},[_c('div',{class:[
                                    'font-weight-medium text-h5 text-center text--darken-4',
                                    `${watcher.color}--text`,
                                  ]},[_vm._v(" "+_vm._s(watcher.value)+" ")]),_c('div',{class:[
                                    'font-weight-medium subtitle-2 text-center text--darken-4',
                                    `${watcher.color}--text`,
                                  ]},[_vm._v(" "+_vm._s(watcher.title)+" ")])])],1)}),1)],1)],1)],1)}),1)]}}])})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"5"}},[_c('Subheader',{attrs:{"icon":"mdi-timer-sand-complete","title":"Bevorstehende Ablaufdaten"}}),_c('div',{staticClass:"mt-7"},[_c('v-data-table',{staticClass:"elevation-1 rounded",attrs:{"headers":_vm.headers,"items":_vm.ablaufdaten,"item-key":"meta.id","items-per-page":10,"sort-by":"ablaufdatum","sort-desc":true,"group-by":_vm.groupBy,"group-desc":_vm.groupDesc,"max-height":"800px auto"},on:{"update:groupBy":function($event){_vm.groupBy=$event},"update:group-by":function($event){_vm.groupBy=$event},"update:groupDesc":function($event){_vm.groupDesc=$event},"update:group-desc":function($event){_vm.groupDesc=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function({ items, isOpen, toggle }){return [_c('th',{staticStyle:{"height":"32px"},attrs:{"colspan":_vm.headers.length}},[_c('v-icon',{attrs:{"left":""},on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? "mdi-chevron-up" : "mdi-chevron-down")+" ")]),_vm._v(" "+_vm._s(_vm.getHeaderText(items[0].ablaufdatum))+" ")],1)]}}])})],1)],1)],1)],1)]],2),(_vm.checkIfSupport)?_c('section',[_c('support-tools',{attrs:{"sources":_vm.code}})],1):_vm._e(),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }