<template>
  <div>
    <NavigationBar titel="Fristenüberwachung" isPreview></NavigationBar>
    <TippCard hintID="Mitgliederverwaltung_Fristenüberwachung_1_V1" text="Text">
    </TippCard>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="start">
            <v-col cols="12" sm="12" md="7">
              <Subheader
                icon="mdi-binoculars"
                title="Überwachte Fristen"
                :actions="actions"
              >
              </Subheader>
              <div class="mt-7">
                <v-data-iterator
                  :items="fristen"
                  item-key="meta.id"
                  :sort-desc="false"
                  no-data-text="Es werden keine Fristen überwacht"
                >
                  <template v-slot:default="props">
                    <v-row>
                      <v-col
                        v-for="item in props.items"
                        :key="item.meta.id"
                        cols="12"
                      >
                        <v-card link @click="routeDetails(item.meta.id)">
                          <v-card-title class="subheading">
                            {{ item.titel }}
                            <v-spacer></v-spacer>
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-card-title>
                          <v-card-text>
                            <v-row dense>
                              <v-col
                                v-for="watcher in item.watchers"
                                :key="watcher.color"
                                cols="sm"
                              >
                                <v-card
                                  class="pa-2"
                                  :color="`${watcher.color} lighten-4`"
                                  dark
                                  :disabled="watcher.value == 0"
                                  outlined
                                >
                                  <div
                                    :class="[
                                      'font-weight-medium text-h5 text-center text--darken-4',
                                      `${watcher.color}--text`,
                                    ]"
                                  >
                                    {{ watcher.value }}
                                  </div>
                                  <div
                                    :class="[
                                      'font-weight-medium subtitle-2 text-center text--darken-4',
                                      `${watcher.color}--text`,
                                    ]"
                                  >
                                    {{ watcher.title }}
                                  </div>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="5">
              <Subheader
                icon="mdi-timer-sand-complete"
                title="Bevorstehende Ablaufdaten"
              >
              </Subheader>
              <div class="mt-7">
                <v-data-table
                  :headers="headers"
                  :items="ablaufdaten"
                  item-key="meta.id"
                  :items-per-page="10"
                  sort-by="ablaufdatum"
                  :sort-desc="true"
                  :group-by.sync="groupBy"
                  :group-desc.sync="groupDesc"
                  class="elevation-1 rounded"
                  max-height="800px auto"
                >
                  <template v-slot:group.header="{ items, isOpen, toggle }">
                    <th :colspan="headers.length" style="height: 32px">
                      <v-icon left @click="toggle"
                        >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                      </v-icon>
                      {{ getHeaderText(items[0].ablaufdatum) }}
                    </th>
                  </template></v-data-table
                >
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import { MEMBER_MANAGEMENT_DEADLINES_create } from "@/data/permission-types.js";

export default {
  name: "member-management-deadline-monitoring",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
    TippCard,
  },
  data() {
    return {
      groupBy: "ablaufdatum",
      groupDesc: true,
      headers: [
        { text: "Name", value: "name" },
        { text: "Wert", value: "wert" },
        { text: "Ablaufdatum", value: "ablaufdatum" },
        { text: "Status", value: "status" },
      ],
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus",
          permission: `${MEMBER_MANAGEMENT_DEADLINES_create}`,
          actionStyle: "textBtn",
          function: this.fristErstellen,
        },
      ],
    };
  },
  computed: {
    fristen() {
      return [
        {
          titel: "Tauglichkeit nach G26.3",
          meta: { id: "1" },
          watchers: [
            { color: "green", value: 24 },
            { color: "orange", value: 5 },
            { color: "red", value: 0 },
          ],
        },
        {
          titel: "Gültiger Atemschutznachweis",
          meta: { id: "2" },
          watchers: [
            { color: "green", value: 24, title: "Gültig" },
            { color: "orange", value: 5, title: "Nächste 30 Tage" },
            { color: "red", value: 0, title: "Abgelaufen" },
          ],
        },
        {
          titel: "Gültiger ANT-Nachweis (Training)",
          meta: { id: "2" },
          watchers: [
            { color: "green", value: 24, title: "Gültig" },
            { color: "orange", value: 5, title: "Nächste 30 Tage" },
            { color: "red", value: 0, title: "Abgelaufen" },
          ],
        },
        {
          titel: "Gültiger ANT-Nachweis (Überprüfung)",
          meta: { id: "2" },
          watchers: [
            { color: "green", value: 24, title: "Gültig" },
            { color: "orange", value: 5, title: "Nächste 30 Tage" },
            { color: "red", value: 0, title: "Abgelaufen" },
          ],
        },
        {
          titel: "Gültiger Führerschein (Klassen C1, C, CE)",
          meta: { id: "2" },
          watchers: [
            { color: "green", value: 24, title: "Gültig" },
            { color: "orange", value: 5, title: "Nächste 30 Tage" },
            { color: "red", value: 0, title: "Abgelaufen" },
          ],
        },
      ];
    },
    ablaufdaten() {
      return [
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
      ];
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "fristen", data: this.fristen }];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getHeaderText(date) {
      return date;
    },
    fristErstellen() {
      this.$router.push({
        name: "member-management-deadline-monitoring-deadline-new",
      });
    },
    routeDetails(item) {
      this.$router.push({
        name: "member-management-deadline-monitoring-deadline-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },
  },
};
</script>
