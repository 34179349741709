<template>
  <div class="mitgliederverwaltung-fristennueberwachung">
    <NavigationBar
      showBackBtn
      titel="Details"
      :actions="actions"
    ></NavigationBar>
    <TippCard hintID="Mitgliederverwaltung_Fristenüberwachung_1_V1" text="Text">
    </TippCard>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="start">
            <v-col cols="12" sm="12" md="6">
              <Subheader icon="mdi-card-text" title="Allgemein"> </Subheader>
              <div class="mt-7">
                <v-row>
                  <v-col cols="12">
                    <v-card>
                      <v-card-title class="subheading">
                        {{ data.titel }}
                      </v-card-title>
                      <v-card-subtitle>{{ data.beschreibung }}</v-card-subtitle>
                      <v-card-text>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-map-marker</v-icon>
                          </v-list-item-icon>
                          <v-list-item-subtitle
                            >{{ data.fristentyp }}
                          </v-list-item-subtitle>
                        </v-list-item>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <Subheader icon="mdi-binoculars" title="Überwachte Frist">
              </Subheader>
              <div class="mt-7">
                <v-row>
                  <v-col cols="12">
                    <v-card>
                      <v-card-title class="subheading">
                        {{ data.titel }}
                      </v-card-title>
                      <v-card-text>
                        <v-row dense>
                          <v-col
                            v-for="data in data.watchers"
                            :key="data.color"
                            cols="sm"
                          >
                            <v-card
                              class="pa-2 text-h5 rounded"
                              :color="data.color"
                              dark
                              :disabled="data.value == 0"
                              outlined
                              tile
                            >
                              <div style="text-align: center">
                                {{ data.value }}
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <Subheader
                icon="mdi-calendar-alert"
                title=" Fristen für diesen Wert"
              >
              </Subheader>
              <div class="mt-7">
                <v-data-table
                  :headers="headers"
                  :items="ablaufdaten"
                  item-key="meta.id"
                  :items-per-page="10"
                  class="elevation-2"
                  max-height="800px auto"
                ></v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import {
  MEMBER_MANAGEMENT_DEADLINES_update,
  MEMBER_MANAGEMENT_DEADLINES_delete,
} from "@/data/permission-types.js";

export default {
  name: "member-management-deadline-monitoring",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
    TippCard,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permissions: `${MEMBER_MANAGEMENT_DEADLINES_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },
        {
          title: "Löschen",
          permissions: `${MEMBER_MANAGEMENT_DEADLINES_delete}`,
          icon: "mdi-delete",
          actionStyle: "textBtn",
          function: this.deleteItem,
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],
      data: {
        titel: "Tauglichkeit nach G26.3",
        beschreibung: "Lorem ipsum",
        fristentyp: "Jährlich",
        meta: { id: "1" },
        watchers: [
          { color: "success", value: 24 },
          { color: "warning", value: 5 },
          { color: "error", value: 0 },
        ],
      },
      headers: [
        { text: "Name", value: "name" },
        { text: "Ablaufdatum", value: "ablaufdatum" },
        { text: "Status", value: "status" },
      ],
    };
  },
  computed: {
    ablaufdaten() {
      return [
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
      ];
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "fristen", data: this.fristen }];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    fristErstellen() {
      this.$router.push({
        name: "member-management-deadline-monitoring-deadline-new",
      });
    },
    routeDetails(item) {
      this.$router.push({
        name: "member-management-deadline-monitoring-deadline-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },
    editItem() {},
    deleteItem() {},
  },
};
</script>
